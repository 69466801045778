.title {
    margin-bottom: 10px;
    color: #888;
    font-size: 1.1em;
  }
  
  .subtitle {
    margin-bottom: 30px;
    color: #aaa;
    font-size: 0.75em;
  }
  
  /* Optional: Ensure the button is vertically centered in case of any padding/margin discrepancies */
  .analyseButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
  }
  
  .customFeedback {
    width: 100%;
    margin-top: 1rem;
    color: #c2c2c2; /* Bootstrap "success" color, adjust as needed for different types of messages */
  }
  
  .textInputCustom::placeholder {
    color: #bbb !important;
    opacity: 1; /* Ensure the color is not transparent */
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    color: #444;
    font-size: 0.7em;
  }
  
  /* Search.module.css */
  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
  }
  
  .modalContent {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    max-width: 1024px;
    width: 90%;
    z-index: 1055;
  }
  
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
  }
  
  .modalTitle {
    margin: 0;
    font-weight: 600;
    font-size: 1.25rem;
    color: #333;
  }
  
  .modalBody {
    max-height: calc(100vh - 210px);
    overflow-y: auto;
  }
  
  .modalBodyContent {
    font-family: monospace;
    color: #333;
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 4px;
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
    font-size: 0.6em;
  }
  
  .btn-close {
    cursor: pointer;
    border: none;
    background-color: transparent;
    font-size: 24px;
    color: #000;
  }
  
  /* Adjusting the close button appearance */
  .btn-close:hover,
  .btn-close:focus {
    color: #007bff;
    text-decoration: none;
    opacity: 0.75;
  }
  