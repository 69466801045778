.topHeader {
  height: 50px;
  background-color: #0E1133;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0 12px;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
}

.subHeader {
  font-family: 'Merriweather', sans-serif;
  height: 70px;
  background-color: #E0F2F1;
  color: #3C5582;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.content {
  display: flex;
  height: calc(100vh - 120px);
}

.section {
  flex: 1;
  overflow: auto;
  padding: 30px;
}

.content .section:nth-of-type(2) {
  border-left: 2px solid #E0F2F1; /* Replace #000 with the color of your choice */
}

.tabs {
  display: flex;
  justify-content: space-around;
}

.nav-link, .button, .activeTab, .removeButton, .addButton {
  flex: 1;
  text-align: center;
  padding: 10px;
  border: none;
  cursor: pointer;
}

.activeTab {
  background: white;
}

.deactiveTab {
  background: #f8f8f8;
  color: #888;
}

.logo {
  height: 24px;
  margin-right: 10px;
}

.globeContainer {
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: start; /* Align horizontally to the start */
}

.globe {
  /* Adjust width as necessary */
  width: 100px;
  height: auto;
}

.labels {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align text to the left */
  margin-left: 15px;
}

.scoreLabel {
  font-family: 'Merriweather', sans-serif;
  font-size: 1.5em; /* Larger text */
}

.matchLabel {
  font-family: 'Lato', sans-serif;
  font-style: italic;
  color: #8FC6D7;
  font-size: 1em; /* Smaller text */
}

.scoredCompanies {
  position: relative; /* Positioning context for the overlay */
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.recentlyScored {
  border: 1px solid rgb(210, 218, 171);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
  background-color: #fffeea !important;
}

.scoredCompany {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  background-color: #f3f2d0;
  min-height: 75px;
  border-radius: 5px;
  padding: 10px;
}

.scoredName {
  font-family: 'Lato', sans-serif;
  font-size: 1em;
  font-weight: bold;
  text-align: left;
  min-width: 20%;
}

.scoredLogo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 16px;
  margin-left: 10px;
  /* shadow */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.scoredName a {
  color: #3C5582;
  text-decoration: none;
}

.scoredScore {
  display: flex;           /* Enables flexbox layout */
  align-items: center;     /* Centers content vertically */
  justify-content: center; /* Centers content horizontally (optional) */
  font-family: 'Lato', sans-serif;
  font-size: 1em;
  margin-left: 10px;
  text-align: center;
  font-weight: bold;
  min-width: 36px;
  min-height: 36px;
  border-radius: 5px;
}

.scoredExplanation {
  font-family: 'Lato', sans-serif;
  font-size: 0.9em;
  margin-left: 20px;
  text-align: left;
  font-style: italic;
  color: #3C5582;
}

.ratingField {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.ratingField input,
.ratingField textarea {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  border: none;
  border-bottom: 1px solid #ace7e4;
  border-radius: 4px;
}

/* Applies when the fields are focused/active */
.ratingField input:focus,
.ratingField textarea:focus {
  border: none; /* Ensures no borders are visible */
  border-bottom: 1px solid #ace7e4; /* Keeps only the bottom border */
  outline: none; /* Removes the default focus outline that browsers apply */
}

.ratingField input::placeholder,
.ratingField textarea::placeholder {
  color: #bbb !important;
  opacity: 1;
  /* Ensure the color is not transparent */
}

.field {
  margin-bottom: 20px;
  text-align: left;
}

.field label {
  display: block;
  text-align: left;
  margin-bottom: 5px;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #3C5582;
}

.field input,
.field textarea,
.field select {
  width: 100%;
  padding: 5px 0px;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  border: none; /* Removes all borders */
  border-bottom: 1px solid #ace7e4; /* Keeps only the bottom border */
  border-radius: 4px; /* You can keep or remove the border-radius as per your design preference */
}

/* Applies when the fields are focused/active */
.field input:focus,
.field textarea:focus,
.field select:focus {
  border: none; /* Ensures no borders are visible */
  border-bottom: 1px solid #ace7e4; /* Keeps only the bottom border */
  outline: none; /* Removes the default focus outline that browsers apply */
}

.field textarea {
  height: 100px;
  resize: vertical;
}

.field input::placeholder,
.field textarea::placeholder {
  color: #bbb !important;
  opacity: 1;
  /* Ensure the color is not transparent */
}

.ratingsTable {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ratingsRow {
  display: flex;
  border-radius: 5px;
  margin: 2px 0;
  align-items: center; /* Align items vertically in the center */
}

.ratingsColumn {
  flex: 1; /* Ensures equal width for all columns */
  padding: 0 10px; /* Spacing inside each column */
  text-align: left; /* Aligns text to the left */
}

.removeButton, .addButton {
  margin-top: 10px;
  background-color: #009688;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer; /* Indicates clickable button */
}

.scoreButton {
  background-color: #222;
}

.suggestButton {
  background-color: #E0F2F1;
}
.suggestButton:hover {
  background-color: #b5e2e0;
}

.addButton {
  width: 100%; /* Full width button for 'Add new' */
}

.editableField, .dropdown {
  flex: 1;
  padding: 8px;
  margin-right: 10px; /* Spacing between fields */
  font-size: 14px;;
}

.dropdown {
  cursor: pointer;
  background-color: white;
}

.scoredCompaniesSection {
  position: relative; /* Positioning context for the overlay */
  /* Ensure this div has defined dimensions or is controlled by its content */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  border-radius: 8px;
  align-items: top;
  padding-top: 100px;
  z-index: 10; /* Adjust z-index as needed */
  backdrop-filter: blur(8px); /* Apply a blur effect to the background */
}

.overlayContent {
  display: flex;
  flex-direction: column; /* Align children vertically */
  align-items: center; /* Center-align children horizontally */
  text-align: center;
  padding: 20px;
  width: 80%; /* Adjust width as needed */
  max-width: 500px;
}

.overlayLabel {
  font-size: 18px;
  color: #333;
  margin-bottom: 20px; /* Space between label and button */
}

.overlayButton {
  font-size: 16px;
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #222;
}

/* Responsive design for mobile devices */
@media (max-width: 768px) {
  .content {
      flex-direction: column;
  }
  .section {
      flex: none;
      height: auto;
  }
  .tabs button {
      width: 50%;
  }
}
