.container {
  padding: 20px;
  font-family: 'Lato', sans-serif;
  text-align: left;
}

.companyName {
  font-size: 24px;
  margin-bottom: 20px;
}

.profileGuid {
  font-size: 0.8em;
  color: #aaa;
}

.textarea {
  width: 100%;
  height: 250px;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button {
  display: inline-block;
  margin-bottom: 20px;
}


.progressContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.progressBarContainer {
  flex: 1;
  height: 20px;
  background-color: #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 10px;
}

.progressBar {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.2s ease;
}

.progressLabel {
  min-width: 50px;
  text-align: right;
  font-size: 14px;
  color: #777;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  font-family: 'Lato', sans-serif;
  font-size: 0.8em;
}

.table th, .table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.table th {
  background-color: #f2f2f2;
}

.error {
  color: red;
}
